export enum IPC_CHANNELS {
  UPDATE_CONFIG = 'update config',
  GET_CONFIG = 'get config',
  ON_UPDATE_CONFIG = 'on update config',
  PARSE_VCARD = 'parse vCard',
  OPEN_NEW_WINDOW = 'open new window',
  TEST_MESSAGE = 'test-message',
  IS_SCREEN_READER_ON = 'is screen reader on',
  SET_SCREEN_READER = 'set screen reader',
  OPEN_EXTERNAL_LINK = 'open external link',
  TOGGLE_AUTO_START = 'toggle auto start',

  // Tray & Protocol
  OPEN_SETTINGS ='open settings',
  TOGGLE_DND = 'toggle dnd',
  CLIENT_LOGIN_STATUS = 'client login status',
  APP_READY = 'app ready',
  START_CALL_FROM_PROTOCOL = 'start call from protocol',
  START_CALL_FROM_PROTOCOL_WITH_ACTION = 'start call from protocol with action',

  // Status
  SET_STATUS = 'set status',
  //incoming
  OPEN_NEW_INCOMING_WINDOW = 'open new incoming window',
  CLOSE_INCOMING_WINDOW = 'close incoming window',
  UPDATE_INCOMING_CALL = 'update incoming call',
  ACCEPT_INCOMING_CALL = 'accept incoming call',
  REJECT_INCOMING_CALL = 'reject incoming call',

  // Incoming messages
  OPEN_INCOMING_MESSAGE_WINDOW = 'open incoming message window',
  CLOSE_INCOMING_MESSAGE_WINDOW = 'close incoming message window',
  OPEN_INCOMING_MESSAGES_VIEW = 'open incoming messages view',
  CLOSE_ALL_MESSAGE_NOTIFICATIONS = 'close all message notifications',
  DISMISS_MESSAGE = 'dismiss message',

  // Updates
  CHECK_UPDATE = 'check update',
  UPDATE_AVAILABLE = 'update available',
  UPDATE_DOWNLOADED = 'update downloaded',
  UPDATE_NOT_AVAILABLE = 'update not available',
  IS_UPDATE_DOWNLOADED = 'is update downloaded',
  QUIT_AND_UPDATE = 'quit and update',
  UPDATE_ERROR = 'update error',
  IS_DOWNLOADING_UPDATE = 'is downloading update',

  // ContextMenus
  OPEN_CONTEXT_MENU = 'open context menu',
  SELECTED_CONTEXT_MENU_OPTION = 'select context menu option'
}

// Configuration model.
export interface ConfigModel {
  temporary_config?: TemporaryConfigModel;
  is_mac?: boolean;
  update_url?: {
    win32: string,
    win64: string,
    mac: string
  };
  update_enabled?: boolean;
  env?: string;
  language?: string;
  settings?: {
    audio?: {
      audio_input_device?: string | boolean;
      audio_output_device?: string | boolean;
      audio_output_volume?: number;
      audio_ring_device?: string | boolean;
      audio_ring_volume?: number;
    },
    notifications?: DESKTOP_NOTIFICATIONS;
    doNotDisturb?: boolean;
    callWaiting?: boolean;
    runAtStartup?: boolean;
    appFocus?: boolean;
    crm: {
      inbound: {
        active: boolean;
        primaryCRM: string;
        open: InboundOpenMode;
      },
      outbound: {
        active: boolean;
      }
    },
    externalPopup?: {
      externalLink: string;
      openingMode: ExternalPopupOpeningModes
    }
  }
}

// This will hold configs that should not be saved on disk (i.e. dev mode)
export interface TemporaryConfigModel {
  dev?: boolean;
  os?: string;
  arch?: PROCESS_ARCH;
  platform?: string;
  machine_id?: string;
}

export enum PROCESS_ARCH {
  x32 = '32',
  x64 = '64'
}

// Models for Desktop notifications
export enum DESKTOP_NOTIFICATIONS {
  standard = 'standard',
  priority = 'priority',
  fullscreen = 'fullscreen',
  disabled = 'disabled'
}

// Models for CRM settings
export enum CRMFilters {
  phone = 'phone',
  email = 'email',
  firstname = 'firstname',
  lastname = 'lastname',
  company = 'company'
}

export enum InboundOpenMode {
  ringing = 'ringing',
  answering = 'answering'
}

export enum APP_STATUS {
  online = 'online',
  offline = 'offline',
  dnd = 'dnd'
}

export enum ExternalPopupOpeningModes {
  disabled = 'disabled',
  open_on_ring = 'open-on-ring',
  open_on_answer = 'open-on-answer'
}

export interface UpdaterDelegates {
  checkingUpdateDelegate?: Function;
  updateAvailableDelegate?: Function;
  updateNotAvailableDelegate?: Function;
  updateDownloadedDelegate?: Function;
  beforeQuitForUpdateDelegate?: Function;
  errorDelegate?: Function;
}

export enum ContextMenuTypes {
  input = 'input',
  selection = 'selection'
}

// used for action that must be executed in webcontents (e.g. paste / cut)
export enum ContextMenuActions {
  paste = 'paste',
  cut = 'cut'
}

export interface ContextMenuData {
  action?: ContextMenuActions;
  hasSelection?: boolean;
  textData?: string;
  serializedData?: string; // will need JSON.parse to unserialize
}